import React from "react";


const CTA = (props) => {
	return (
		<div className="cs-footer_wrap cs-bg" data-src="/assets/img/footer_bg_1.png">
			<div className="container wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
				<div className="cs-cta cs-style1 cs-accent_bg text-center">
					<h2 className="cs-cta_title text-uppercase cs-white_color cs-font_60 cs-font_36_sm cs-m0">JOIN THE FROG ARMY NOW</h2>
					<div className="cs-height_10 cs-height_lg_10"></div>
					<div className="cs-cta_subtitle cs-white_color">Come on over and join our discord.... </div>
					<div className="cs-height_30 cs-height_lg_30"></div>
					<div className="cs-cta_btns cs-center">
						<a target="_blank" rel="noreferrer" href={props.discord} className="cs-btn cs-btn_filed cs-white_btn_2">
						<svg xmlns="https://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
						<g fill="currentColor" stroke="currentColor" strokeWidth="2">
						<path className="st0" d="M20.3,4.6c-1.6-0.7-3.2-1.3-5-1.6c-0.2,0.4-0.5,0.9-0.6,1.3C12.9,4,11.1,4,9.3,4.3C9.1,3.9,8.8,3.4,8.6,3
		c-1.7,0.3-3.4,0.8-5,1.6c-3.1,4.7-4,9.4-3.6,13.9C2.2,20,4.2,21,6.2,21.6c0.5-0.7,0.9-1.4,1.3-2.1c-0.7-0.3-1.4-0.6-2-1
		c0.2-0.1,0.3-0.3,0.5-0.4c3.9,1.8,8.2,1.8,12.1,0c0.2,0.1,0.3,0.3,0.5,0.4c-0.6,0.4-1.3,0.7-2.1,1c0.4,0.8,0.8,1.5,1.3,2.1
		c2-0.6,4-1.6,6.1-3.1C24.4,13.2,23,8.6,20.3,4.6z M8,15.7c-1.2,0-2.2-1.1-2.2-2.5s1-2.5,2.2-2.5c1.2,0,2.2,1.1,2.2,2.5
		C10.2,14.6,9.2,15.7,8,15.7z M16,15.7c-1.2,0-2.2-1.1-2.2-2.5s1-2.5,2.2-2.5c1.2,0,2.2,1.1,2.2,2.5C18.1,14.6,17.2,15.7,16,15.7z"
		/>
						</g>
					</svg>           
						<span>Join Our Community</span>
						</a>
						
					</div>
				</div>
			</div>
		</div>
	);
};

export default CTA;
