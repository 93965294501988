// js imports
import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import BigNumber from "bignumber.js";
import {MerkleTree} from "merkletreejs"
import keccak256 from "keccak256"
import frenlist from "./frenlist/frenlist.json";
import giftlist from "./frenlist/giftlist.json";


// contract
import ABI from "./contracts/ABI.json";
import getWeb3 from "./getWeb3";

// components
import Menu from "./components/Menu";
import MenuSpacer from "./components/MenuSpacer";
import Hero from "./components/Hero";
import Pause from './components/Pause'
import Squad from './components/Squad2'
import CTASpacer from "./components/CTASpacer";
import Footer from "./components/Footer";
import Timer from './components/Timer'
import Counters from "./components/Counters";
import CTA from './components/CTA'
import Roadmap from './components/RoadMap'
import AboutSpacer from './components/AboutSpacer'
import About from './components/About'
import Team from './components/Team'
import MintInfo from './components/MintInfo'
import Faq from './components/Faq'

// strings
const contractAddress = "0x4734e795bc71513af17c466f9de5f358e44a74f0";
const discordLink = "https://discord.gg/nuYgPwCt"
const telegramLink = "https://t.me/alcatrazswimteam";
const listLink = "https://tinyurl.com/alcatrazswimteam";

// gift list
let leavesGift = giftlist.map(addr => keccak256(addr))
let merkleTreeGift = new MerkleTree(leavesGift, keccak256, {sortPairs: true});
let rootHashGift = merkleTreeGift.getRoot().toString('hex')


const useMountEffect = (fun) => useEffect(fun, [])

// emitter to help with jquery resets.
function emit(eventName) {
    const event = new CustomEvent(eventName, {  });
    document.dispatchEvent(event);
}


function Alcatraz() {

	const[account, setAccount] = useState(null);
	const[web3, setWeb3] = useState(null);
	const[contract, setContract] = useState(null);
	const [mySquad, setMySquad] = useState([]);
	const [hasSquad, setHasSquad] = useState([]);
	const [squadLoaded, setSquadLoaded] = useState(false);
	const [mainMintingOpen ,setMainMintingOpen] = useState(false);

	const [totalMinted, setTotalMinted] = useState(0);
	const [totalMints, setTotalMints] = useState(0);
	const [totalTeamMints, setTotalTeamlMints] = useState(0);

	const [mainCosts, setMainCost] = useState(0);
	const [currentCardLimit, setCurrenCardLimit] = useState(0);
  const [currenIssueCount, setCurrentIssueCount] = useState(0);
	const [value, setValue] = useState(1);
	const [isSoldOut, setIsSoldOut] = useState(true);
	const [minting, setMinting] = useState(false);

	const [FrenListStart, setFrenListStart] = useState(0);
	const [FrenListStartEpoch, setFrenListStartEpoch] = useState(0);
	const [MaintStart, setMainStart] = useState(0);
	const [MainStartEpoch, setMainStartEpoch] = useState(0);
	const [inGiftList, setInGiftList] = useState(false);
	const [myGiftAllowance, setGiftAllowance] = useState(0);
	const [giftListMinted, setGiftListMinted] = useState(0);

	const [frenListMintingOpen, setFrenListMintingOpen] = useState(false)

	const [hasSetup, setHasSetup] = useState(false);

	const [lastMintedUrl, setLastminted] = useState([{image:"/assets/img/mystery.jpg",opensea:"http://opensea.io/collection/alcatraz-swim-team"},{image:"/assets/img/mystery.jpg",opensea:"http://opensea.io/collection/alcatraz-swim-team"},{image:"/assets/img/mystery.jpg",opensea:"http://opensea.io/collection/alcatraz-swim-team"},{image:"/assets/img/mystery.jpg",opensea:"http://opensea.io/collection/alcatraz-swim-team"},{image:"/assets/img/mystery.jpg",opensea:"http://opensea.io/collection/alcatraz-swim-team"},{image:"/assets/img/mystery.jpg",opensea:"http://opensea.io/collection/alcatraz-swim-team"},{image:"/assets/img/mystery.jpg",opensea:"http://opensea.io/collection/alcatraz-swim-team"},{image:"/assets/img/mystery.jpg",opensea:"http://opensea.io/collection/alcatraz-swim-team"},{image:"/assets/img/mystery.jpg",opensea:"http://opensea.io/collection/alcatraz-swim-team"}])

	const [firstRun,setFirstRun] =useState(true);

	const [showMintDialog, setShowMintDialog] = useState(false);
	const [showGiftMintDialog, setShowGiftMintDialog] = useState(false);

const connectWallet = ()=>{

}


	const handleCount = (type = "inc") => {

		let max = 10;

		if (type === "inc") {
			if (value < max) {
				setValue(value + 1);
			}
		} else {
			if (value > 1) {
				setValue(value - 1);
			}
		}
	};

	const openMintDialog = ()=> {
		setShowMintDialog(true);
	}
	const openGiftMintDialog = ()=> {
		setShowGiftMintDialog(true);
	}
	const closeMintDialog = ()=> {
		setShowMintDialog(false);
	}
	const closeGiftMintDialog = ()=> {
		setShowGiftMintDialog(false);
	}

	const handleGiftCount = (type = "inc") => {
		console.log(`gift minted: ${giftListMinted} already`)
		let max = myGiftAllowance || 0 ;
		if(myGiftAllowance <= giftListMinted){
			max = 0
		}

		if((myGiftAllowance - giftListMinted) >=10){
			max = (myGiftAllowance-giftListMinted)
		}


		if(max >=10){
			max = 10
		}

		if (type === "inc") {
			if (value < max) {
				setValue(value + 1);
			}
		} else {
			if (value > 1) {
				setValue(value - 1);
			}
		}
	};


	const getCount = async (contract) => {
		const count = await contract.methods.totalSupply().call();
		setTotalMinted(parseInt(count));
	};
	const maxMints = async (contract) => {
		const totalMints = await contract.methods.totalMints().call();
		console.log({totalMints})
		setTotalMints(parseInt(totalMints));
	};
	const maxTeamMints = async (contract) => {
		const totalMints = await contract.methods.totalTeamMints().call();
		console.log({totalMints})
		setTotalTeamlMints(parseInt(totalMints));
	};
	const getMyGiftAllowance = async (contract, address) => {
		const count = await contract.methods.giftListMintLimits(address).call();
		console.log('limits are:')
		console.log(count)
		setGiftAllowance(parseInt(count));
	};


		const getMainCosts = async (contract) => {
			const cost = await contract.methods.mintRate().call();
			setMainCost(parseInt(cost));
		};

		const getFrenListStart = async (contract) => {
			const start = await contract.methods.frenListMintStart().call();
			console.log({frenListMintstart:start.toString()})
			const humanDate = new Date(parseInt(start.toString())*1000)
			setFrenListStart(humanDate.toLocaleString())
			;
		};
		const getFrenListMintingOpen = async (contract) => {
			const start = await contract.methods.frenListMintStart().call();
			console.log({'frenlistOpen':start});
			setFrenListMintingOpen(Date.now()>(Number(start)*1000));

			console.log('is fren listmain minting open?',  Date.now()>(Number(start)*1000), Date.now(), Number(start)*1000)
		};
		const getMainMintingOpen = async (contract) => {
			const start = await contract.methods.mainMintStart().call();
			setMainMintingOpen(Date.now()>parseInt(start)*1000);
			console.log('is main minting open?', mainMintingOpen, Date.now()>parseInt(start)*1000, Date.now(), parseInt(start)*1000)
		};

		const getFrenListStartEpoch = async (contract) => {
			const period = await contract.methods.frenListMintStart().call();
			console.log({frenListMintstartPeriod:period.toString()})
			setFrenListStartEpoch(parseInt(period.toString()*1000));
		};

		const getMainStart = async (contract) => {
			const start = await contract.methods.mainMintStart().call();
			console.log({frenListMintstart:start.toString()})
			const humanDate = new Date(parseInt(start.toString())*1000)
			setMainStart(humanDate.toLocaleString());
		};

		const getMainStartEpoch = async (contract) => {
			const period = await contract.methods.mainMintStart();
			console.log({mainMintstartPeriod:period.toString()})
			setMainStartEpoch(parseInt(period.toString()*1000));
		};
		const getGiftListMinted = async (contract, address) => {
			const count = await contract.methods.giftListMinted(address).call();

			setGiftListMinted(count);
		};



		const getIsSoldOut = async (contract) => {
			const cardCount = await contract.methods.totalSupply().call();
			const cardLimit = await contract.methods.totalMints().call();
			const cardTeamLimit = await contract.methods.totalTeamMints().call();
			setIsSoldOut(cardCount === (cardLimit-cardTeamLimit));
		};

		// const isFren = async(address)=>{
		// 	const leaf = keccak256(address);
		// 	const proof = merkleTree.getHexProof(leaf);
		// 	const isFrenList = merkleTree.verify(proof, leaf, rootHash);
		//
		// }




	const mintMainToken = async () => {



		if (!account) {
			return toast.error("To be able to mint, you need to connect your wallet.", { position: "bottom-right" });
			}

			const costs = await contract.methods.mintRate().call();


			try {
				setMinting(true)
				//alert(costs)
				const costsBN = new BigNumber(costs).multipliedBy(value);
				const tx = await contract.methods.mint(value).send({value:costsBN,from:account,gasLimit:140000*value+''});
				console.log(tx)


				getCount(contract);
				getLastMintedUrl(contract);
				setMinting(false);

				return toast.success("Mint Successful", { position: "bottom-right" });
			} catch (error) {
				setMinting(false);
				return toast.error(error.message||"Mint Error", { position: "bottom-right" });

			}


	};


	const  mintGiftToken = async () => {

		if (!account) {
			return toast.error("To be able to mint, you need to connect your wallet.", { position: "bottom-right" });

		  }

		  const leaf = keccak256(account);
		  const proof = merkleTreeGift.getHexProof(leaf);

		  // Verify Merkle Proof
		  const isValid = merkleTreeGift.verify(proof, leaf, rootHashGift);

		  if (!isValid) {
			  toast.error("Invalid Merkle Proof - You are not on the gift list :( ", { position: "bottom-right" })
		  return;
			}


			  try {
			  setMinting(true)


				const result = await contract.methods
				.giftListMint(proof, value).send({
					gasLimit:160000*value+'',
					from: account
				  })






			//   console.log(tx)

			  setMinting(false);
			  getLastMintedUrl(contract);
			  getCount(contract);
			  return toast.success("Mint Successful", { position: "bottom-right" });
		  } catch (error) {
			setMinting(false);
			return toast.error(error.message||"Mint Error", { position: "bottom-right" });
		  }
		};










	const getTransferEvents = async (contract, address)=>{


		const eventsTo  = await contract.getPastEvents('Transfer', {
			filter:
			{	from:null,
				to:address
			},
			fromBlock: 0,
			toBlock: 'latest'
		}, function(error, events){ console.log(events); })
		.then(function(events){
		   return events;
		});


		const eventsFrom  = await contract.getPastEvents('Transfer', {
			filter:
			{	from:address
			},
			fromBlock: 0,
			toBlock: 'latest'
		}, function(error, events){ console.log(events); })
		.then(function(events){
		    console.log(events) // same results as the optional callback above
				return events;
		});

		let allEvents = [...eventsTo || [], ...eventsFrom ||[]] // concatenate arrays using spread operator
		console.log({allEvents})
		allEvents.sort((a, b) => parseFloat(`${a.blockNumber}.${a.transactionIndex}`) - parseFloat(`${b.blockNumber}.${b.transactionIndex}`))

		return allEvents;
	}

	const getMySquad = async (contract, address)=>{

		console.log("SETTING SQUAD")
		// const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
		const count = await contract.methods.balanceOf(address).call();

		if(parseInt(count)>0){
			setHasSquad(true)
			// view transactions and decide on tokenid array.
			const squad = [];
			const transfers = await getTransferEvents(contract, address);
			//console.log({transfers});
			if(transfers){
			for (let i = 0; i < transfers.length; i++) {
				const element = transfers[i];

				if ((element.returnValues.to).toUpperCase() === (address).toUpperCase()){
					squad.push(element.returnValues.tokenId);
				} else{
					squad.splice(squad.indexOf(element.returnValues.tokenId),1)
				}

				//console.log(Number(count), squad.length);
				if (Number(count) === squad.length){

					if(squad.length>50 ){
						toast.success(`MEGA WHALE ALERT, welcome back. Your squad is rolling ${count} deep. welcome back.`)
					}else if(squad.length>10){
						toast.success(`Hey we got a whale-to-be over here!, Your squad is rolling ${count} deep. welcome back.`)
					} else if(squad.length<10){
						toast.success(`Holding ${count}, welcome back.`)
					}

					const squadDetails = []
					let validUpdate = false;
					for (let squadIndex = 0; squadIndex < squad.length; squadIndex++) {
						if((squadIndex+1) !== squad[squadIndex]){
						validUpdate = true;

						const pepeId = await contract.methods.setIDs(squad[squadIndex]).call();

						squadDetails.push({
							image: "https://arweave.net/u6ZpsxyARDaULetOFFfIK0pAzUGuNiJyQMERT449VSQ/"+pepeId+".png",
							opensea:"https://opensea.io/assets/ethereum/0x4734e795bc71513af17c466f9de5f358e44a74f0/"+squad[squadIndex],
							escapeeNo:squad[squadIndex],
							pepeId:pepeId
						})
						}
					}

					if(validUpdate){
					//console.log({squadDetails})
					setMySquad(squadDetails.reverse());
					setSquadLoaded(true)
					//emit('contentChanged')
					//emit('contentChanged')
					} else {
						console.log('not a valid update..')
						setSquadLoaded(true)
					}
				} else {
					console.log('Could not match lagth to squad...')
					setSquadLoaded(true)
				}

			}
		} else {
			 console.log('NO TRANSFERS');
			 setSquadLoaded(true)
			 setHasSquad(false)
		 }
		} else {
			console.log('fell 1')
			setSquadLoaded(true)
			setHasSquad(false)
		}
	};

	const applyProvider =  async()=>{
				const myWeb3 = await getWeb3();
				setWeb3(myWeb3);
				const accounts = await myWeb3.eth.getAccounts();
				setAccount(accounts[0]);
				const instance = new myWeb3.eth.Contract(
			    ABI,
			    1 && contractAddress,
			  );
				setContract(instance);
				console.log({instance})


				getCount(instance)
				getMainStart(instance);
				getMainMintingOpen(instance);
				getFrenListMintingOpen(instance);
				maxMints(instance);
				maxTeamMints(instance);
				getIsSoldOut(instance);
				getMainCosts(instance);
				getMyGiftAllowance(instance, accounts[0]);
				getInGiftList(accounts[0]);
				getGiftListMinted(instance, accounts[0])
				getLastMintedUrl(instance);
				getMySquad(instance, accounts[0]);

				// await getFrenListMintingOpen(instance);

	}

	useMountEffect(()=>{
		if(firstRun){
		applyProvider();
		setFirstRun(false);
		}
	},[]);

	const getInGiftList = async(address)=>{
		const leaf = keccak256(address);
		const proof = merkleTreeGift.getHexProof(leaf);
		const inGiftList = merkleTreeGift.verify(proof, leaf, rootHashGift);
		setInGiftList(inGiftList)
	}

	const getLastMintedUrl = async (contract)=>{
		const id = await contract.methods.totalSupply().call();
		const pepeIds = await contract.methods.getSetIDSlice(id-10,id).call();
		console.log({id,pepeIds});
		const latestMints = [];

		let subtractMeIncrementor = 0
		for (let tokenId = 10; tokenId > 1; tokenId--) {

			latestMints.push({
				image: "https://arweave.net/u6ZpsxyARDaULetOFFfIK0pAzUGuNiJyQMERT449VSQ/"+pepeIds[tokenId]+".png",
				opensea:"https://opensea.io/assets/ethereum/0x4734e795bc71513af17c466f9de5f358e44a74f0/"+(parseInt(id)-subtractMeIncrementor)
			})
			subtractMeIncrementor++

		}
		console.log(latestMints)
		setLastminted(latestMints);
	}

	return (
			<>
				<ToastContainer />
				<Menu openMintDialog={openMintDialog}/>
				<MenuSpacer></MenuSpacer>
				<Hero image={lastMintedUrl} openMintDialog={openMintDialog}></Hero>
				<MenuSpacer></MenuSpacer>
				<Squad squad={mySquad} loaded={squadLoaded}  hasSquad={hasSquad} currentAccount={account}></Squad>
				<Pause image={lastMintedUrl}></Pause>
				<CTASpacer/>
				<Counters></Counters>
				<CTA discord={discordLink} telegram={telegramLink} list={listLink}></CTA>
				<Roadmap></Roadmap>
				<AboutSpacer></AboutSpacer>
				<About discord={discordLink}></About>
				<AboutSpacer></AboutSpacer>
				<Team  discord={discordLink} telegram={telegramLink}></Team>
				<AboutSpacer></AboutSpacer>
				<MintInfo></MintInfo>
				<Faq></Faq>
				<Footer discord={discordLink} telegram={telegramLink} openGiftMintDialog={openGiftMintDialog}></Footer>


				<div className={showMintDialog?'cs-modal active':'cs-modal'}>
					<div className="cs-modal_in">
						<div className="cs-modal_container cs-white_bg">
							<button className="cs-close_modal cs-center cs-primary_bg" onClick={closeMintDialog}>
							<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="https://www.w3.org/2000/svg">
							<path d="M11.7071 1.70711C12.0976 1.31658 12.0976 0.683417 11.7071 0.292893C11.3166 -0.0976311 10.6834 -0.0976311 10.2929 0.292893L11.7071 1.70711ZM0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683417 12.0976 1.31658 12.0976 1.70711 11.7071L0.292893 10.2929ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L10.2929 11.7071ZM10.2929 0.292893L0.292893 10.2929L1.70711 11.7071L11.7071 1.70711L10.2929 0.292893ZM0.292893 1.70711L10.2929 11.7071L11.7071 10.2929L1.70711 0.292893L0.292893 1.70711Z" fill="white"/>
							</svg>
							</button>
							<div className="cs-mint_secton">
								<h2 className="cs-font_32 text-uppercase cs-normal cs-m0 text-center">{ mainMintingOpen ?"Mint Now":"OPEN MINT STARTS SOON"}</h2>


									<div className="cs-height_25 cs-height_lg_25"></div>


								<div className="cs-mint_avatar text-center"
								><img className="mystery-pepe" src={lastMintedUrl[0].image} alt=""/><br/>
								(This was the last mint, good luck!)
								</div>
								<div className="cs-height_50 cs-height_lg_30"></div>
								{account ? (
								<div>
									<p className="connected-to-address"> Connected to:	{account}</p>
								<ul className="cs-list cs-style2 cs-mp0 cs-primary_color cs-primary_font">
								<li>
								<div className="cs-list_left">Remaining</div>
								<div className="cs-list_right"><span className="focus">{totalMints-totalMinted} of {totalMints}</span></div>
								</li>
								<li>
								<div className="cs-list_left">Quantity / <span className="cs-accent_color">{(mainCosts)/1000000000000000000} ETH</span></div>
								<div className="cs-list_right">
								<div className="cs-quantity">
									<button className="cs-quantity_btn cs-center" onClick={() => handleCount("dec")}>
									<svg xmlns="https://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M20 12H4"/></svg>
									</button>
									<div className="count">{value}</div>
									<button className="cs-quantity_btn cs-center" onClick={() => handleCount("inc")}>
									<svg xmlns="https://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M12 20v-8m0 0V4m0 8h8m-8 0H4"/></svg>
									</button>
								</div>
								</div>
								</li>
								<li>
								<div className="cs-list_left">Total Price</div>
								<div className="cs-list_right">{(mainCosts*value)/1000000000000000000}</div>
								</li>
								</ul>
								<div className="cs-height_25 cs-height_lg_25"></div>


								<div>
								<div>
								{!isSoldOut ? (

									<div>
										<div>
										<p>We are currently over estimatng on gas to avoid any 'out of gas' transactions.</p>
										</div>
										<button disabled={minting ? true : false}  onClick={mintMainToken} className="cs-btn cs-btn_filed cs-accent_btn text-center text-uppercase w-100">
											<div>{minting ? "Minting..." : "Mint Now "}</div>
										</button>
									</div>
								):(
									<div><h2></h2></div>
								)}
								</div>
								</div>




								</div>
					        ) : (

							// eslint-disable-next-line jsx-a11y/anchor-is-valid
							<a
								className="connect-btn"
								href={
									window.ethereum
										? "#"
										: "https://alcatrazswimteam.com"
								}
								data-aos="zoom-in"
							>
								<button className="cs-btn cs-btn_filed cs-accent_btn text-center text-uppercase w-100"  >{account ? "Connected" : "Connect Wallet"}</button>
							</a>
							)}

								<div className="cs-height_15 cs-height_lg_15"></div>

								<p className="cs-m0 text-center">Main Sale : { mainMintingOpen? isSoldOut?"Sold Out":"Minting" : "Not Open For Minting Yet"}</p>
							</div>
						</div>
					</div>
				</div>

				<div className="cs-modal" className={showGiftMintDialog?'cs-modal active':'cs-modal'}>
					<div className="cs-modal_in">
						<div className="cs-modal_container cs-white_bg">
							<button className="cs-close_modal cs-center cs-primary_bg" onClick={closeGiftMintDialog}>
							<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="https://www.w3.org/2000/svg">
							<path d="M11.7071 1.70711C12.0976 1.31658 12.0976 0.683417 11.7071 0.292893C11.3166 -0.0976311 10.6834 -0.0976311 10.2929 0.292893L11.7071 1.70711ZM0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683417 12.0976 1.31658 12.0976 1.70711 11.7071L0.292893 10.2929ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L10.2929 11.7071ZM10.2929 0.292893L0.292893 10.2929L1.70711 11.7071L11.7071 1.70711L10.2929 0.292893ZM0.292893 1.70711L10.2929 11.7071L11.7071 10.2929L1.70711 0.292893L0.292893 1.70711Z" fill="white"/>
							</svg>
							</button>

							<div className="cs-mint_secton">
							<h2 className="cs-font_32 text-uppercase cs-normal cs-m0 text-center">GIFT-MINT PASS HOLDERS WELCOME { frenListMintingOpen?"NOW": "SOON"}:</h2>
							<div className="refresh-warning">
								<p> 1: Please double check only one account is connected to your metamask</p>
								<p> 2: If you are revisiting please ensure you hard refresh your browser here, to ensure you are being served the latest version</p>
				 				<p> 3: Scroll down :)</p>
							</div>
							{ frenListMintingOpen ? (

									<div className="cs-height_25 cs-height_lg_25"></div>
								):(
									<div>

										<div className="cs-height_25 cs-height_lg_25"></div>
										<Timer deadline={FrenListStartEpoch||1665514800*1000}></Timer>
										<p>Follow our <a href="https://twit`ter.com/alacatraz_swim">Alctaraz Swim Team Twitter</a> to find out how to get yours!</p>
										<div className="cs-height_25 cs-height_lg_25"></div>
									</div>
								)}

								<div className="cs-mint_avatar text-center">
									<img className="mystery-pepe" src={lastMintedUrl[0].image} alt=""/>
									<br/>
									(This was the last mint, good luck!)
								</div>
								<div className="cs-height_50 cs-height_lg_30"></div>
								{account && frenListMintingOpen ? (
									<div>
										<div>
										{inGiftList ? (


										<div>
										<p className="connected-to-address"> Connected to:	{account}</p>

										{/* <p>{usedFrenListMints} fren mints left.</p> */}
								<ul className="cs-list cs-style2 cs-mp0 cs-primary_color cs-primary_font">
								<li>
								<div className="cs-list_left">Remaining</div>
								<div className="cs-list_right"><span className="focus">{totalMints-totalMinted} of {totalMints}</span></div>
								</li>
								<li>
								<div className="cs-list_left">Quantity / <span className="cs-accent_color">0 ETH</span></div>
								<div className="cs-list_right">
								<div className="cs-quantity">
									<button className="cs-quantity_btn cs-center" onClick={() => handleGiftCount("dec")}>
									<svg xmlns="https://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M20 12H4"/></svg>
									</button>
									<div className="count">{value}</div>
									<button className="cs-quantity_btn cs-center" onClick={() => handleGiftCount("inc")}>
									<svg xmlns="https://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M12 20v-8m0 0V4m0 8h8m-8 0H4"/></svg>
									</button>
								</div>
								</div>
								</li>

								</ul>
								</div>
								):(
									<div></div>
								)}
								</div>
								<div className="cs-height_25 cs-height_lg_25"></div>
								<p>{ inGiftList ?'Your address is on the gift list':'Not on the gift list'}</p>
								 <p> Allowance: {myGiftAllowance+", Used "+giftListMinted} ...so... {myGiftAllowance-giftListMinted} left to mint</p>


									<div>

									{  (inGiftList && ((inGiftList ? giftListMinted:myGiftAllowance) < myGiftAllowance) )  ? (

										<div>
											{!isSoldOut ? (
									<div>
										<button  disabled={minting && inGiftList ? true : false}  onClick={mintGiftToken} className="cs-btn cs-btn_filed cs-accent_btn text-center text-uppercase w-100">
											<div>{minting ? "Minting ..." : "Mint"}</div>
										</button>
									</div>
								):(
									<div><h2></h2></div>
								)}
										</div>






									):(
										<p>{inGiftList ?"No more frens mints left":""}</p>
									)}
										</div>
									</div>
					        ) : (

							// eslint-disable-next-line jsx-a11y/anchor-is-valid
							<a
								className="connect-btn"
								href={
									window.ethereum
										? "#"
										: "https://alcatrazswimteam.com"
								}
								onClick={connectWallet}
								data-aos="zoom-in"
							>
								<button className="cs-btn cs-btn_filed cs-accent_btn text-center text-uppercase w-100"  >{account ? "Connected" : "Connect Wallet"}</button>
							</a>
							)}

								<div className="cs-height_15 cs-height_lg_15"></div>
								<p className="cs-m0 text-center">Early Mint : { frenListMintingOpen? isSoldOut?"Sold Out" : "Minting" : "Not Open For Minting Yet"}</p>
								<div>
								</div>
							</div>
						</div>
					</div>
				</div>


				{account}
			</>
		)
}

export default Alcatraz;
