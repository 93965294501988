import React from "react";


const AboutSpacer = (props) => {
	return (
		<div className="cs-height_100 cs-height_lg_70"></div>
	);
};

export default AboutSpacer;
