import React from "react";


const Counters = (props) => {
	return (
		<div className="container">
		<div className="row">
		  <div className="col-lg-3 col-6 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
			<div className="cs-funfact cs-style1 cs-white_bg text-center">
			  <div className="cs-height_60 cs-height_lg_60"></div>
			  <div className="cs-font_60 cs-font_36_sm cs-m0 cs-primary_font cs-primary_color cs-heading_line_height cs-bold"><span data-count-to="9600" className="odometer"></span></div>
			  <p className="cs-m0 cs-font_30 cs-heading_line_height cs-font_22_sm">Total Items</p>
			  <div className="cs-height_65 cs-height_lg_65"></div>
			</div>
			<div className="cs-height_25 cs-height_lg_25"></div>
		  </div>
		  <div className="col-lg-3 col-6 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s">
			<div className="cs-funfact cs-style1 cs-white_bg text-center">
			  <div className="cs-height_60 cs-height_lg_60"></div>
			  <div className="cs-font_60 cs-font_36_sm cs-m0 cs-primary_font cs-primary_color cs-heading_line_height cs-bold"><span data-count-to="51" className="odometer"></span></div>
			  <p className="cs-m0 cs-font_30 cs-heading_line_height cs-font_22_sm">Seed Pepes</p>
			  <div className="cs-height_65 cs-height_lg_65"></div>
			</div>
			<div className="cs-height_25 cs-height_lg_25"></div>
		  </div>
		  <div className="col-lg-3 col-6 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
			<div className="cs-funfact cs-style1 cs-white_bg text-center">
			  <div className="cs-height_60 cs-height_lg_60"></div>
			  <div className="cs-font_60 cs-font_36_sm cs-m0 cs-primary_font cs-primary_color cs-heading_line_height cs-bold"><span data-count-to="260" className="odometer"></span></div>
			  <p className="cs-m0 cs-font_30 cs-heading_line_height cs-font_22_sm">Attributes</p>
			  <div className="cs-height_65 cs-height_lg_65"></div>
			</div>
			<div className="cs-height_25 cs-height_lg_25"></div>
		  </div>
		  <div className="col-lg-3 col-6 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
			<div className="cs-funfact cs-style1 cs-white_bg text-center">
			  <div className="cs-height_60 cs-height_lg_60"></div>
			  <div className="cs-font_60 cs-font_36_sm cs-m0 cs-primary_font cs-primary_color cs-heading_line_height cs-bold"><span data-count-to="2000" className="odometer"></span></div>
			  <p className="cs-m0 cs-font_30 cs-heading_line_height cs-font_22_sm">FrensList Spots</p>
			  <div className="cs-height_65 cs-height_lg_65"></div>
			</div>
			<div className="cs-height_25 cs-height_lg_25"></div>
		  </div>
		</div>
	  </div>
	);
};

export default Counters;
