import React from "react";


const About = (props) => {

	const imagePrev = (prev, image)=>{
		const prefix = "https://arweave.net/lVGStCbPogCtD4s6AgsnDpL4DYxpTVawJBbP6TNk2f4/"
		const suffix = ".png"
		console.log(image);
		console.log(image.replace(suffix,'').replace(prefix,''))
		console.log({img:prefix+(String(Number(image.replace(suffix,'').replace(prefix,''))-prev) )+suffix})
		console.log('number is:' + Number(image.replace(suffix,'').replace(prefix,'')));
		return prefix+(String(Number(image.replace(suffix,'').replace(prefix,''))-prev) )+suffix
	}
	const openseaslink = (prev, image)=>{
		const prefix = "https://arweave.net/lVGStCbPogCtD4s6AgsnDpL4DYxpTVawJBbP6TNk2f4/"
		const NewPrefix = "https://opensea.io/assets/ethereum/0x4734e795bc71513af17c466f9de5f358e44a74f0/"
		const suffix = ".png"
		console.log(image);
		console.log(image.replace(suffix,'').replace(prefix,''))
		console.log({img:prefix+(String(Number(image.replace(suffix,'').replace(prefix,''))-prev) )})
		console.log('number is:' + Number(image.replace(suffix,'').replace(prefix,'')));
		return NewPrefix+(String(Number(image.replace(suffix,'').replace(prefix,''))-prev) )
	}
	return (
		<section id="about">
		<div className="cs-height_70 cs-height_lg_40"></div>
		<div className="container">
		  <div className="row align-items-center flex-column-reverse-lg">
		  <div className="col-lg-7 wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.2s">
			  <div className="cs-height_0 cs-height_lg_40"></div>
			  <div className="cs-right_space_150">
				<div className="cs-seciton_heading cs-style1 text-uppercase">
				<h2 className="cs-cta_title text-uppercase cs-white_color cs-font_60 cs-font_36_sm cs-m0">LIVE-LFG-MINTs</h2>
				  <h3 className="cs-section_title cs-font_16 cs-font_14_sm cs-gradient_color">Live Escape Mints.</h3>
				 
				</div>
				<div className="cs-height_20 cs-height_lg_20"></div>
				<p>Get comfy at the CCTV Screens, and view live escapees now...</p>
				<p>Watch the mints as they come out... did you just miss a grail? Give yer frens deep regret as they see your dank mints flow from the project.</p>
				
				<div className="cs-height_25 cs-height_lg_15"></div>
				<div className="cs-btn_group">
				<a target="_blank" rel="noreferrer" href="https://opensea.io/collection/alcatraz-swim-team" className="cs-btn cs-btn_bordered">
			
					<span>Join the party on opensea....</span>
				  </a>
				  <a href="#" className="cs-btn cs-btn_bordered cs-modal_btn" data-modal="mint-main">
			
					<span>Get Minted!</span>
				  </a>
				</div>
			  </div>
			</div>
			<div className="col-lg-5 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
			  <div className="row text-center">
	

				<div className="col-4">
				<div className="cs-height_60 cs-height_lg_30"></div>
				  <a href={props.image[0].opensea} target="_blank" rel="noreferrer">
				  <img src={props.image[0].image} alt=""/>
				  </a>
				  <div className="cs-height_25 cs-height_lg_25"></div>
				  <a href={props.image[3].opensea} target="_blank" rel="noreferrer">
				  <img src={props.image[3].image} alt=""/>
				  </a>
				  <div className="cs-height_25 cs-height_lg_25"></div>
				  <a href={props.image[6].opensea} target="_blank" rel="noreferrer">
				  <img src={props.image[6].image} alt=""/>
				  </a>
				</div>
				<div className="col-4">
				<div className="cs-height_60 cs-height_lg_30"></div>
				<a href={props.image[1].opensea} target="_blank" rel="noreferrer">
				  <img src={props.image[1].image} alt=""/>
				  </a>
				  <div className="cs-height_25 cs-height_lg_25"></div>
				  <a href={props.image[4].opensea} target="_blank" rel="noreferrer">
				  <img src={props.image[4].image} alt=""/>
				  </a>
				  <div className="cs-height_25 cs-height_lg_25"></div>
				  <a href={props.image[7].opensea} target="_blank" rel="noreferrer">
				  <img src={props.image[7].image} alt=""/>
				  </a>
				</div>
				<div className="col-4">
				<div className="cs-height_60 cs-height_lg_30"></div>
				<a href={props.image[2].opensea} target="_blank" rel="noreferrer">
				  <img src={props.image[2].image} alt=""/>
				  </a>
				  <div className="cs-height_25 cs-height_lg_25"></div>
				  <a href={props.image[5].opensea} target="_blank" rel="noreferrer">
				  <img src={props.image[5].image} alt=""/>
				  </a>
				  <div className="cs-height_25 cs-height_lg_25"></div>
				  <a href={props.image[8].opensea} target="_blank" rel="noreferrer">
				  <img src={props.image[8].image} alt=""/>
				  </a>
				  <div className="cs-height_25 cs-height_lg_25"></div>
				</div>
			  </div>
			</div>
		  </div>
		</div>
	  </section>
	);
};

export default About;
