import React from "react";


const Faq = (props) => {
	return (
		<section id="faq">
		<div className="cs-height_70 cs-height_lg_40"></div>
		<div className="container">
		<div className="cs-seciton_heading cs-style1 text-uppercase text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
			<h3 className="cs-section_title cs-font_16 cs-font_14_sm cs-gradient_color">Frogquently Asked Questions</h3>
			<h2 className="cs-section_subtitle cs-m0 cs-font_36 cs-font_24_sm">Still have questions?</h2>
		</div>
		<div className="cs-height_50 cs-height_lg_30"></div>
		<div className="row cs-accordians cs-style1 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
			<div className="col-lg-8 offset-lg-2">
			<div className="cs-accordian cs-white_bg active">
				<div className="cs-accordian_head">
				<h2 className="cs-accordian_title cs-m0 cs-font_22 cs-font_18_sm"><span>Q1.</span> Is this all ya got?</h2>
				<span className="cs-accordian_toggle"></span>
				</div>
				<div className="cs-accordian-body">
				No, more details about features and more details on the known features will be shared as we move forward, stay tuned.
				</div>
			</div>
			<div className="cs-height_25 cs-height_lg_25"></div>
			<div className="cs-accordian cs-white_bg">
				<div className="cs-accordian_head">
				<h2 className="cs-accordian_title cs-m0 cs-font_22 cs-font_18_sm"><span>Q2.</span> What is an NFT?</h2>
				<span className="cs-accordian_toggle"></span>
				</div>
				<div className="cs-accordian-body">
					An NFT is a Non fungible Token, here the features of which are used to track your individual tokens (avatars) to their owner, among other nifty features.
					At its core the principle is that with an ERC721A token like this one, your NFT is not just any 1 of 10,000 is it specifically (for example) the individual #2736 of 10,0000 along with all of its specific metadata and imagery.
				</div>
			</div>
			<div className="cs-height_25 cs-height_lg_25"></div>
			<div className="cs-accordian cs-white_bg">
				<div className="cs-accordian_head">
				<h2 className="cs-accordian_title cs-m0 cs-font_22 cs-font_18_sm"><span>Q3.</span> Can I meme it?</h2>
				<span className="cs-accordian_toggle"></span>
				</div>
				<div className="cs-accordian-body">
				Ser, Meme at will -  these are <a target="_blank" rel="noreferrer" href="https://creativecommons.org/share-your-work/public-domain/cc0/">CC0</a>.
				</div>
			</div>
			<div className="cs-height_25 cs-height_lg_25"></div>
			<div className="cs-accordian cs-white_bg">
				<div className="cs-accordian_head">
				<h2 className="cs-accordian_title cs-m0 cs-font_22 cs-font_18_sm"><span>Q4.</span> I know nothing about NFTs where do i even start?</h2>
				<span className="cs-accordian_toggle"></span>
				</div>
				<div className="cs-accordian-body">
				You can start engaging with web3 and NFTs by simply creating yourself an ethereum wallet - which can be as simple as installing metamask browser plugin.
				We would certainly advise you spend some time understanding the basics of cryptocurrency before funding your wallet. Cryptocurrency and NFT markets are brutally volatile - only buy what you like, and only because you like it.
				Do your own research, and reach out to our growing community if you need help.
				</div>
			</div>
			<div className="cs-height_25 cs-height_lg_25"></div>
			<div className="cs-accordian cs-white_bg">
				<div className="cs-accordian_head">
				<h2 className="cs-accordian_title cs-m0 cs-font_22 cs-font_18_sm"><span>Q5.</span>Random Mints?</h2>
				<span className="cs-accordian_toggle"></span>
				</div>
				<div className="cs-accordian-body">
					In Alctraz Swim Team, each mint is random and unpredictable to its minter. The final set is generated before minting is opened but each minter receives a random and unforeseeable PepeFP from the collection. <br/>Who receives what can not be orchestrated or engineered.
				</div>
			</div>

			<div className="cs-height_25 cs-height_lg_25"></div>
			<div className="cs-accordian cs-white_bg">
				<div className="cs-accordian_head">
				<h2 className="cs-accordian_title cs-m0 cs-font_22 cs-font_18_sm"><span>Q6.</span>Team Allocation?</h2>
				<span className="cs-accordian_toggle"></span>
				</div>
				<div className="cs-accordian-body">
				The team decided to be able to mint 100 of these each, theyll do so alongside other minters. The aim here is to keep the team focused on what future holds for us all, and to be driven to make it interesting and have a long lifespan.
				</div>
			</div>
			<div className="cs-height_25 cs-height_lg_25"></div>
			</div>
		</div>
		</div>
	</section>
	);
};

export default Faq;



