import React from "react";
import Countdown from "react-countdown";

const renderer = ({ weeks, days, hours, minutes, seconds }) => {
	return (
		<div className="timer" data-aos="flip-up">
			<div className="row">
				<div className="col">
					<h2>{days}</h2>
					<p>DAYS</p>
				</div>
				<div className="col">
					<h2>{hours}</h2>
					<p>HOURS</p>
				</div>
				<div className="col">
					<h2>{minutes}</h2>
					<p>MINUTES</p>
				</div>
				<div className="col">
					<h2>{seconds}</h2>
					<p>SECONDS</p>
				</div>
			</div>
		</div>
	);
};

const Timer = (props) => {


	return <Countdown date={props.deadline} renderer={renderer} />;
};

export default Timer;
