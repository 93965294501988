import React from "react";


const Footer = (props) => {
	return (

	<footer className="cs-footer text-center">
		<div className="container">
		{/* <div className="cs-height_100 cs-height_lg_70"></div> */}
		<div className="cs-footer_logo wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
		<img src="/assets/img/small-blackout-logo-crop.png" alt="logo" className="cs-hide_dark"/>
		<img src="/assets/img/small-whiteout-logo-crop.png" alt="logo" className="cs-hide_white"/>
		</div>
		<div className="cs-height_25 cs-height_lg_25"></div>
		<div className="cs-social_btns cs-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">

		<a href="https://twitter.com/alcatraz_swim" className="cs-center cs-primary_color cs-accent_bg_hover cs-white_bg cs-white_color_hover">
		<svg xmlns="https://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M23.643 4.937c-.835.37-1.732.62-2.675.733a4.67 4.67 0 0 0 2.048-2.578a9.3 9.3 0 0 1-2.958 1.13a4.66 4.66 0 0 0-7.938 4.25a13.229 13.229 0 0 1-9.602-4.868c-.4.69-.63 1.49-.63 2.342A4.66 4.66 0 0 0 3.96 9.824a4.647 4.647 0 0 1-2.11-.583v.06a4.66 4.66 0 0 0 3.737 4.568a4.692 4.692 0 0 1-2.104.08a4.661 4.661 0 0 0 4.352 3.234a9.348 9.348 0 0 1-5.786 1.995a9.5 9.5 0 0 1-1.112-.065a13.175 13.175 0 0 0 7.14 2.093c8.57 0 13.255-7.098 13.255-13.254c0-.2-.005-.402-.014-.602a9.47 9.47 0 0 0 2.323-2.41l.002-.003Z"/></svg>
		</a>

		<a target="_blank" rel="noreferrer" href={props.discord} className="cs-center cs-primary_color cs-accent_bg_hover cs-white_bg cs-white_color_hover">

		<svg xmlns="https://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
						<g fill="currentColor" stroke="currentColor" strokeWidth="2">
						<path className="st0" d="M20.3,4.6c-1.6-0.7-3.2-1.3-5-1.6c-0.2,0.4-0.5,0.9-0.6,1.3C12.9,4,11.1,4,9.3,4.3C9.1,3.9,8.8,3.4,8.6,3
		c-1.7,0.3-3.4,0.8-5,1.6c-3.1,4.7-4,9.4-3.6,13.9C2.2,20,4.2,21,6.2,21.6c0.5-0.7,0.9-1.4,1.3-2.1c-0.7-0.3-1.4-0.6-2-1
		c0.2-0.1,0.3-0.3,0.5-0.4c3.9,1.8,8.2,1.8,12.1,0c0.2,0.1,0.3,0.3,0.5,0.4c-0.6,0.4-1.3,0.7-2.1,1c0.4,0.8,0.8,1.5,1.3,2.1
		c2-0.6,4-1.6,6.1-3.1C24.4,13.2,23,8.6,20.3,4.6z M8,15.7c-1.2,0-2.2-1.1-2.2-2.5s1-2.5,2.2-2.5c1.2,0,2.2,1.1,2.2,2.5
		C10.2,14.6,9.2,15.7,8,15.7z M16,15.7c-1.2,0-2.2-1.1-2.2-2.5s1-2.5,2.2-2.5c1.2,0,2.2,1.1,2.2,2.5C18.1,14.6,17.2,15.7,16,15.7z"
		/>
						</g>
					</svg>
		</a>
		</div>
		<div className="cs-height_30 cs-height_lg_30"></div>
		<ul className="cs-footer_menu cs-primary_font cs-primary_color cs-center text-uppercase wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s">
		<li><a href="#home"  className="cs-smoth_scroll">Alcatraz Swim Team</a></li>
		<li><a href="#about"  className="cs-smoth_scroll">Frogs</a></li>

		<li><a href="#roadmap"  className="cs-smoth_scroll">Lillypads</a></li>
		<li><a href="team"  className="cs-smoth_scroll">Army</a></li>
		<li><a href="faq"  className="cs-smoth_scroll">FAQ</a></li>
		<li><span className="cs-modal_btn" onClick={props.openGiftMintDialog}>My Gift Mint</span></li>

		</ul>
		</div>

		<div className="cs-height_25 cs-height_lg_25"></div>
	</footer>
	);
};

export default Footer;
