import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import Web3Modal from "web3modal";

const infuraId = "0cb58b812624401785797c057338fa2d";

export const providerOptions = {
  cacheProvider: true,
 coinbasewallet: {
   package: CoinbaseWalletSDK,
   options: {
     appName: "AlcatrazSwimTeam.com",
     infuraId
   }
 },
 walletconnect: {
   package: WalletConnectProvider,
   options: {
     infuraId
   }
 }
};

const getWeb3 = async() =>
  new Promise(async (resolve, reject) => {



    // Modern dapp browsers...
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      try {
        // Request account access if needed
        await window.ethereum.enable();
        // Acccounts now exposed
        resolve(web3);
      } catch (error) {
        reject(error);
      }
      console.log('has window.ethereum')
    }
    // Legacy dapp browsers...
    else if (window.web3) {
      // Use Mist/MetaMask's provider.
      const web3 = window.web3;
      console.log("Injected web3 detected.");
      resolve(web3);
    }
    // Fallback to localhost; use dev console port by default...
    else {
    //   const web3provider = new WalletConnectProvider({
    //   infuraId: "959fd9c4dc65443bb52542215e70c37f ",
    // });
      //await web3provider.enable();
      //const web3 = new Web3(web3provider);
      const web3Modal = new Web3Modal({
          providerOptions // required
        });

      const provider = await web3Modal.connect();
      const web3 = new Web3(provider);
      console.log("No web3 instance injected, using Local web3.");
      resolve(web3);
    }
  });

export default getWeb3;
