import React from "react";


const Roadmap = (props) => {
	return (

  <section id="roadmap">
    <div className="cs-height_70 cs-height_lg_40"></div>
    <div className="container">
      <div className="cs-seciton_heading cs-style1 text-uppercase text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
        <h3 className="cs-section_title cs-font_16 cs-font_14_sm cs-gradient_color">Our LeapPads</h3>
        <h2 className="cs-section_subtitle cs-m0 cs-font_36 cs-font_24_sm">The lillypad trail</h2>
      </div>
      <div className="cs-height_50 cs-height_lg_30"></div>
      <div className="cs-accent_seperator_1 cs-accent_color"></div>
      <div className="row wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
        <div className="col-lg-3 col-md-6">
          <div className="cs-card cs-style2">
            <div className="cs-card_number cs-accent_bg cs-white_color cs-center cs-primary_font cs-font_22 cs-bold">
              1
              <div className="cs-card_tick">
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="https://www.w3.org/2000/svg">
                  <path d="M0.899994 9.90957C0.899994 5.43606 4.52649 1.80957 8.99999 1.80957C13.4735 1.80957 17.1 5.43606 17.1 9.90957C17.1 14.3831 13.4735 18.0096 8.99999 18.0096C4.52649 18.0096 0.899994 14.3831 0.899994 9.90957Z" fill="#06182C" stroke="#0A2540" strokeWidth="2"/>
                  <path d="M8.99999 1.80957C4.52649 1.80957 0.899994 5.43606 0.899994 9.90957C0.899994 14.3831 4.52649 18.0096 8.99999 18.0096C13.4735 18.0096 17.1 14.3831 17.1 9.90957C17.1 5.43606 13.4735 1.80957 8.99999 1.80957ZM12.4755 5.60348L14.1584 7.28636L8.90211 12.5437L7.2291 14.2157L5.54622 12.5328L3.84158 10.8271L5.51359 9.15512L7.21824 10.8608L12.4755 5.60348Z" fill="white"/>
                </svg>                                   
              </div>
            </div>
            <div className="cs-height_30 cs-height_lg_15"></div>
            <div className="cs-card_in cs-white_bg">
              <h3 className="cs-gradient_color cs-font_18 cs-font_16_sm cs-m0">Airhorns</h3>
              <div className="cs-height_5 cs-height_lg_5"></div>
              <h2 className="cs-font_22 cs-font_16_sm cs-m0">Let the world know</h2>
              <div className="cs-height_10 cs-height_lg_10"></div>
              <p className="cs-m0">Getting this part started in the first days of Frogtober - LFG, Share with your frens now!</p>
            </div>
          </div>
          <div className="cs-height_25 cs-height_lg_25"></div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="cs-card cs-style2">
            <div className="cs-card_number cs-accent_bg cs-white_color cs-center cs-primary_font cs-font_22 cs-bold">
              2
              <div className="cs-card_tick">
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="https://www.w3.org/2000/svg">
                  <path d="M0.899994 9.90957C0.899994 5.43606 4.52649 1.80957 8.99999 1.80957C13.4735 1.80957 17.1 5.43606 17.1 9.90957C17.1 14.3831 13.4735 18.0096 8.99999 18.0096C4.52649 18.0096 0.899994 14.3831 0.899994 9.90957Z" fill="#06182C" stroke="#0A2540" strokeWidth="2"/>
                  <path d="M8.99999 1.80957C4.52649 1.80957 0.899994 5.43606 0.899994 9.90957C0.899994 14.3831 4.52649 18.0096 8.99999 18.0096C13.4735 18.0096 17.1 14.3831 17.1 9.90957C17.1 5.43606 13.4735 1.80957 8.99999 1.80957ZM12.4755 5.60348L14.1584 7.28636L8.90211 12.5437L7.2291 14.2157L5.54622 12.5328L3.84158 10.8271L5.51359 9.15512L7.21824 10.8608L12.4755 5.60348Z" fill="white"/>
                </svg>                                    
              </div>
            </div>
            <div className="cs-height_30 cs-height_lg_15"></div>
            <div className="cs-card_in cs-white_bg">
              <h3 className="cs-gradient_color cs-font_18 cs-font_16_sm cs-m0">FrensList!</h3>
              <div className="cs-height_5 cs-height_lg_5"></div>
              <h2 className="cs-font_22 cs-font_16_sm cs-m0">Starting the party</h2>
              <div className="cs-height_10 cs-height_lg_10"></div>
              <p className="cs-m0">Building the frens list</p>
            </div>
          </div>
          <div className="cs-height_25 cs-height_lg_25"></div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="cs-card cs-style2">
            <div className="cs-card_number cs-accent_bg cs-white_color cs-center cs-primary_font cs-font_22 cs-bold">
              3
              {/* <div className="cs-card_tick">
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="https://www.w3.org/2000/svg">
                  <path d="M0.899994 9.90957C0.899994 5.43606 4.52649 1.80957 8.99999 1.80957C13.4735 1.80957 17.1 5.43606 17.1 9.90957C17.1 14.3831 13.4735 18.0096 8.99999 18.0096C4.52649 18.0096 0.899994 14.3831 0.899994 9.90957Z" fill="#06182C" stroke="#0A2540" strokeWidth="2"/>
                  <path d="M8.99999 1.80957C4.52649 1.80957 0.899994 5.43606 0.899994 9.90957C0.899994 14.3831 4.52649 18.0096 8.99999 18.0096C13.4735 18.0096 17.1 14.3831 17.1 9.90957C17.1 5.43606 13.4735 1.80957 8.99999 1.80957ZM12.4755 5.60348L14.1584 7.28636L8.90211 12.5437L7.2291 14.2157L5.54622 12.5328L3.84158 10.8271L5.51359 9.15512L7.21824 10.8608L12.4755 5.60348Z" fill="white"/>
                </svg>                                   
              </div> */}
            </div>
            <div className="cs-height_30 cs-height_lg_15"></div>
            <div className="cs-card_in cs-white_bg">
              <h3 className="cs-gradient_color cs-font_18 cs-font_16_sm cs-m0">The Great Escape</h3>
              <div className="cs-height_5 cs-height_lg_5"></div>
              <h2 className="cs-font_22 cs-font_16_sm cs-m0">Harbouring fugitives</h2>
              <div className="cs-height_10 cs-height_lg_10"></div>
              <p className="cs-m0">Time to 'mint' your Alcatraz Swim Team NFTs, and provide shelter for hardened frog criminals.</p>
            </div>
          </div>
          <div className="cs-height_25 cs-height_lg_25"></div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="cs-card cs-style2">
            <div className="cs-card_number cs-accent_bg cs-white_color cs-center cs-primary_font cs-font_22 cs-bold">
              4
              {/* <div className="cs-card_tick">
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="https://www.w3.org/2000/svg">
                  <path d="M0.899994 9.90957C0.899994 5.43606 4.52649 1.80957 8.99999 1.80957C13.4735 1.80957 17.1 5.43606 17.1 9.90957C17.1 14.3831 13.4735 18.0096 8.99999 18.0096C4.52649 18.0096 0.899994 14.3831 0.899994 9.90957Z" fill="#06182C" stroke="#0A2540" strokeWidth="2"/>
                  <path d="M8.99999 1.80957C4.52649 1.80957 0.899994 5.43606 0.899994 9.90957C0.899994 14.3831 4.52649 18.0096 8.99999 18.0096C13.4735 18.0096 17.1 14.3831 17.1 9.90957C17.1 5.43606 13.4735 1.80957 8.99999 1.80957ZM12.4755 5.60348L14.1584 7.28636L8.90211 12.5437L7.2291 14.2157L5.54622 12.5328L3.84158 10.8271L5.51359 9.15512L7.21824 10.8608L12.4755 5.60348Z" fill="white"/>
                </svg>                                
              </div> */}
            </div>
            <div className="cs-height_30 cs-height_lg_15"></div>
            <div className="cs-card_in cs-white_bg">
              <h3 className="cs-gradient_color cs-font_18 cs-font_16_sm cs-m0">Lets get Physical?</h3>
              <div className="cs-height_5 cs-height_lg_5"></div>
              <h2 className="cs-font_22 cs-font_16_sm cs-m0">Physical Phroggys</h2>
              <div className="cs-height_10 cs-height_lg_10"></div>
              <p className="cs-m0">Burn to Earn, Sacrafice a Pepe to be rewarded with physical goodies.</p>
            </div>
          </div>
          <div className="cs-height_25 cs-height_lg_25"></div>
        </div>
      </div>
    </div>
  </section>

	);
};

export default Roadmap;
