import React from "react";


const MenuSpacer= (props) => {
	return (
		<div className="cs-height_80 cs-height_lg_80"></div>
	);
};

export default MenuSpacer;
