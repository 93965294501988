import React from "react";


const Team = (props) => {
	return (
		<section id="team">
		  <div className="cs-height_70 cs-height_lg_40"></div>
		  <div className="container">
			<div className="cs-seciton_heading cs-style1 text-uppercase text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
			  <h3 className="cs-section_title cs-font_16 cs-font_14_sm cs-gradient_color">collective noun for frogs... "army."</h3>
			  <h2 className="cs-section_subtitle cs-m0 cs-font_36 cs-font_24_sm">Team Army</h2>
			</div>
			<div className="cs-height_50 cs-height_lg_30"></div>
			<div className="cs-slider cs-style1 cs-gap-24 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
			  <div className="cs-slider_container" data-autoplay="0" data-loop="1" data-speed="600" data-center="0" data-fade-slide="0"  data-slides-per-view="responsive" data-xs-slides="1" data-sm-slides="2" data-md-slides="3" data-lg-slides="4" data-add-slides="4">
				<div className="cs-slider_wrapper">
				  <div className="cs-slide">
					<div className="cs-team cs-style1 text-center cs-white_bg">
					  <div className="cs-height_30 cs-height_lg_30"></div>
					  <div className="cs-member_thumb">
						<img src="/assets/img/p1.png" alt="Member"/>
					  </div>
					  <div className="cs-height_25 cs-height_lg_25"></div>
					  <div className="cs-member_info">
						<h2 className="cs-font_22 cs-font_16_sm cs-m0">Viva La Vandal</h2>
						<div className="cs-height_5 cs-height_lg_5"></div>
						<p className="cs-m0">Artist</p>
						<div className="cs-height_20 cs-height_lg_20"></div>
						<div className="cs-member_social cs-center">
						  <a href="https://twitter.com/viva_la_vandal" target="_blank" className="cs-primary_color cs-accent_color_hover">
						  <svg xmlns="https://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
								<path fill="currentColor"d="M21.4,7.9c0,0.2,0,0.4,0,0.6c0,6.5-4.9,13.9-13.9,13.9v0c-2.7,0-5.3-0.8-7.5-2.2c0.4,0,0.8,0.1,1.2,0.1
	c2.2,0,4.3-0.7,6.1-2.1c-2.1,0-3.9-1.4-4.6-3.4c0.7,0.1,1.5,0.1,2.2-0.1C2.6,14.2,1,12.2,1,9.9V9.8c0.7,0.4,1.4,0.6,2.2,0.6
	C1,9,0.4,6.2,1.7,3.9C4.1,7,7.8,8.8,11.7,9c-0.4-1.7,0.1-3.5,1.4-4.7c2-1.9,5.1-1.8,6.9,0.2c1.1-0.2,2.1-0.6,3.1-1.2
	C22.8,4.5,22,5.5,21,6.1c1-0.1,1.9-0.4,2.8-0.8C23.2,6.3,22.4,7.2,21.4,7.9L21.4,7.9z"/>
								</svg>                   
						  </a>
						  <a  href={props.discord} rel="noreferrer" target="_blank" className="cs-primary_color cs-accent_color_hover">
						  <svg xmlns="https://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
						<g fill="currentColor" stroke="currentColor" strokeWidth="2">
						<path className="st0" d="M20.3,4.6c-1.6-0.7-3.2-1.3-5-1.6c-0.2,0.4-0.5,0.9-0.6,1.3C12.9,4,11.1,4,9.3,4.3C9.1,3.9,8.8,3.4,8.6,3
		c-1.7,0.3-3.4,0.8-5,1.6c-3.1,4.7-4,9.4-3.6,13.9C2.2,20,4.2,21,6.2,21.6c0.5-0.7,0.9-1.4,1.3-2.1c-0.7-0.3-1.4-0.6-2-1
		c0.2-0.1,0.3-0.3,0.5-0.4c3.9,1.8,8.2,1.8,12.1,0c0.2,0.1,0.3,0.3,0.5,0.4c-0.6,0.4-1.3,0.7-2.1,1c0.4,0.8,0.8,1.5,1.3,2.1
		c2-0.6,4-1.6,6.1-3.1C24.4,13.2,23,8.6,20.3,4.6z M8,15.7c-1.2,0-2.2-1.1-2.2-2.5s1-2.5,2.2-2.5c1.2,0,2.2,1.1,2.2,2.5
		C10.2,14.6,9.2,15.7,8,15.7z M16,15.7c-1.2,0-2.2-1.1-2.2-2.5s1-2.5,2.2-2.5c1.2,0,2.2,1.1,2.2,2.5C18.1,14.6,17.2,15.7,16,15.7z"
		/>
						</g>
					</svg>                           
						  </a>
						  {/* <a href="#" className="cs-primary_color cs-accent_color_hover">
							<svg xmlns="https://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 15 15"><path fill="currentColor" d="M5 4.768a.5.5 0 0 1 .761.34l.14.836a.5.5 0 0 1-.216.499l-.501.334A4.501 4.501 0 0 1 5 5.5v-.732ZM9.5 10a4.5 4.5 0 0 1-1.277-.184l.334-.5a.5.5 0 0 1 .499-.217l.836.14a.5.5 0 0 1 .34.761H9.5Z"/><path fill="currentColor" fillRule="evenodd" d="M0 7.5a7.5 7.5 0 1 1 3.253 6.182l-2.53 1.265a.5.5 0 0 1-.67-.67l1.265-2.53A7.467 7.467 0 0 1 0 7.5Zm4.23-3.42l.206-.138a1.5 1.5 0 0 1 2.311 1.001l.14.837a1.5 1.5 0 0 1-.648 1.495l-.658.438A4.522 4.522 0 0 0 7.286 9.42l.44-.658a1.5 1.5 0 0 1 1.494-.648l.837.14a1.5 1.5 0 0 1 1.001 2.311l-.138.207a.5.5 0 0 1-.42.229h-1A5.5 5.5 0 0 1 4 5.5v-1a.5.5 0 0 1 .23-.42Z" clipRule="evenodd"/></svg>                         
						  </a> */}
						</div>
						<div className="cs-height_30 cs-height_lg_30"></div>
					  </div>
					</div>
				  </div>
				  <div className="cs-slide">
					<div className="cs-team cs-style1 text-center cs-white_bg">
					  <div className="cs-height_30 cs-height_lg_30"></div>
					  <div className="cs-member_thumb">
						<img src="/assets/img/p2.png" alt="Member"/>
					  </div>
					  <div className="cs-height_25 cs-height_lg_25"></div>
					  <div className="cs-member_info">
						<h2 className="cs-font_22 cs-font_16_sm cs-m0">Indelible</h2>
						<div className="cs-height_5 cs-height_lg_5"></div>
						<p className="cs-m0">Developer</p>
						<div className="cs-height_20 cs-height_lg_20"></div>
						<div className="cs-member_social cs-center">
						  <a href="https://twitter.com/superrarerares" target="_blank" className="cs-primary_color cs-accent_color_hover">
						  <svg xmlns="https://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
								<path fill="currentColor"d="M21.4,7.9c0,0.2,0,0.4,0,0.6c0,6.5-4.9,13.9-13.9,13.9v0c-2.7,0-5.3-0.8-7.5-2.2c0.4,0,0.8,0.1,1.2,0.1
	c2.2,0,4.3-0.7,6.1-2.1c-2.1,0-3.9-1.4-4.6-3.4c0.7,0.1,1.5,0.1,2.2-0.1C2.6,14.2,1,12.2,1,9.9V9.8c0.7,0.4,1.4,0.6,2.2,0.6
	C1,9,0.4,6.2,1.7,3.9C4.1,7,7.8,8.8,11.7,9c-0.4-1.7,0.1-3.5,1.4-4.7c2-1.9,5.1-1.8,6.9,0.2c1.1-0.2,2.1-0.6,3.1-1.2
	C22.8,4.5,22,5.5,21,6.1c1-0.1,1.9-0.4,2.8-0.8C23.2,6.3,22.4,7.2,21.4,7.9L21.4,7.9z"/>
								</svg>                   
						  </a>
						  <a href={props.discord} rel="noreferrer" target="_blank" className="cs-primary_color cs-accent_color_hover">
						  <svg xmlns="https://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
						<g fill="currentColor" stroke="currentColor" strokeWidth="2">
						<path className="st0" d="M20.3,4.6c-1.6-0.7-3.2-1.3-5-1.6c-0.2,0.4-0.5,0.9-0.6,1.3C12.9,4,11.1,4,9.3,4.3C9.1,3.9,8.8,3.4,8.6,3
		c-1.7,0.3-3.4,0.8-5,1.6c-3.1,4.7-4,9.4-3.6,13.9C2.2,20,4.2,21,6.2,21.6c0.5-0.7,0.9-1.4,1.3-2.1c-0.7-0.3-1.4-0.6-2-1
		c0.2-0.1,0.3-0.3,0.5-0.4c3.9,1.8,8.2,1.8,12.1,0c0.2,0.1,0.3,0.3,0.5,0.4c-0.6,0.4-1.3,0.7-2.1,1c0.4,0.8,0.8,1.5,1.3,2.1
		c2-0.6,4-1.6,6.1-3.1C24.4,13.2,23,8.6,20.3,4.6z M8,15.7c-1.2,0-2.2-1.1-2.2-2.5s1-2.5,2.2-2.5c1.2,0,2.2,1.1,2.2,2.5
		C10.2,14.6,9.2,15.7,8,15.7z M16,15.7c-1.2,0-2.2-1.1-2.2-2.5s1-2.5,2.2-2.5c1.2,0,2.2,1.1,2.2,2.5C18.1,14.6,17.2,15.7,16,15.7z"
		/>
						</g>
					</svg>
						  </a>
						  {/* <a href="#" className="cs-primary_color cs-accent_color_hover">
							<svg xmlns="https://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 15 15"><path fill="currentColor" d="M5 4.768a.5.5 0 0 1 .761.34l.14.836a.5.5 0 0 1-.216.499l-.501.334A4.501 4.501 0 0 1 5 5.5v-.732ZM9.5 10a4.5 4.5 0 0 1-1.277-.184l.334-.5a.5.5 0 0 1 .499-.217l.836.14a.5.5 0 0 1 .34.761H9.5Z"/><path fill="currentColor" fillRule="evenodd" d="M0 7.5a7.5 7.5 0 1 1 3.253 6.182l-2.53 1.265a.5.5 0 0 1-.67-.67l1.265-2.53A7.467 7.467 0 0 1 0 7.5Zm4.23-3.42l.206-.138a1.5 1.5 0 0 1 2.311 1.001l.14.837a1.5 1.5 0 0 1-.648 1.495l-.658.438A4.522 4.522 0 0 0 7.286 9.42l.44-.658a1.5 1.5 0 0 1 1.494-.648l.837.14a1.5 1.5 0 0 1 1.001 2.311l-.138.207a.5.5 0 0 1-.42.229h-1A5.5 5.5 0 0 1 4 5.5v-1a.5.5 0 0 1 .23-.42Z" clipRule="evenodd"/></svg>                         
						  </a> */}
						</div>
						<div className="cs-height_30 cs-height_lg_30"></div>
					  </div>
					</div>
				  </div>
				  <div className="cs-slide">
					<div className="cs-team cs-style1 text-center cs-white_bg">
					  <div className="cs-height_30 cs-height_lg_30"></div>
					  <div className="cs-member_thumb">
						<img src="/assets/img/p4.png" alt="Member"/>
					  </div>
					  <div className="cs-height_25 cs-height_lg_25"></div>
					  <div className="cs-member_info">
						<h2 className="cs-font_22 cs-font_16_sm cs-m0">Rare Scrilla</h2>
						<div className="cs-height_5 cs-height_lg_5"></div>
						<p className="cs-m0">Creative Director </p>
						<div className="cs-height_20 cs-height_lg_20"></div>
						<div className="cs-member_social cs-center">
						  <a href="https://twitter.com/scrillaventura" target="_blank" className="cs-primary_color cs-accent_color_hover">
							<svg xmlns="https://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
								<path fill="currentColor"d="M21.4,7.9c0,0.2,0,0.4,0,0.6c0,6.5-4.9,13.9-13.9,13.9v0c-2.7,0-5.3-0.8-7.5-2.2c0.4,0,0.8,0.1,1.2,0.1
	c2.2,0,4.3-0.7,6.1-2.1c-2.1,0-3.9-1.4-4.6-3.4c0.7,0.1,1.5,0.1,2.2-0.1C2.6,14.2,1,12.2,1,9.9V9.8c0.7,0.4,1.4,0.6,2.2,0.6
	C1,9,0.4,6.2,1.7,3.9C4.1,7,7.8,8.8,11.7,9c-0.4-1.7,0.1-3.5,1.4-4.7c2-1.9,5.1-1.8,6.9,0.2c1.1-0.2,2.1-0.6,3.1-1.2
	C22.8,4.5,22,5.5,21,6.1c1-0.1,1.9-0.4,2.8-0.8C23.2,6.3,22.4,7.2,21.4,7.9L21.4,7.9z"/>
								</svg>                   
						  </a>
						  <a  href={props.discord} rel="noreferrer" target="_blank" className="cs-primary_color cs-accent_color_hover">
						  <svg xmlns="https://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
						<g fill="currentColor" stroke="currentColor" strokeWidth="2">
						<path className="st0" d="M20.3,4.6c-1.6-0.7-3.2-1.3-5-1.6c-0.2,0.4-0.5,0.9-0.6,1.3C12.9,4,11.1,4,9.3,4.3C9.1,3.9,8.8,3.4,8.6,3
		c-1.7,0.3-3.4,0.8-5,1.6c-3.1,4.7-4,9.4-3.6,13.9C2.2,20,4.2,21,6.2,21.6c0.5-0.7,0.9-1.4,1.3-2.1c-0.7-0.3-1.4-0.6-2-1
		c0.2-0.1,0.3-0.3,0.5-0.4c3.9,1.8,8.2,1.8,12.1,0c0.2,0.1,0.3,0.3,0.5,0.4c-0.6,0.4-1.3,0.7-2.1,1c0.4,0.8,0.8,1.5,1.3,2.1
		c2-0.6,4-1.6,6.1-3.1C24.4,13.2,23,8.6,20.3,4.6z M8,15.7c-1.2,0-2.2-1.1-2.2-2.5s1-2.5,2.2-2.5c1.2,0,2.2,1.1,2.2,2.5
		C10.2,14.6,9.2,15.7,8,15.7z M16,15.7c-1.2,0-2.2-1.1-2.2-2.5s1-2.5,2.2-2.5c1.2,0,2.2,1.1,2.2,2.5C18.1,14.6,17.2,15.7,16,15.7z"
		/>
						</g>
					</svg>   
						  </a>
						  {/* <a href="#" className="cs-primary_color cs-accent_color_hover">
							<svg xmlns="https://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 15 15"><path fill="currentColor" d="M5 4.768a.5.5 0 0 1 .761.34l.14.836a.5.5 0 0 1-.216.499l-.501.334A4.501 4.501 0 0 1 5 5.5v-.732ZM9.5 10a4.5 4.5 0 0 1-1.277-.184l.334-.5a.5.5 0 0 1 .499-.217l.836.14a.5.5 0 0 1 .34.761H9.5Z"/><path fill="currentColor" fillRule="evenodd" d="M0 7.5a7.5 7.5 0 1 1 3.253 6.182l-2.53 1.265a.5.5 0 0 1-.67-.67l1.265-2.53A7.467 7.467 0 0 1 0 7.5Zm4.23-3.42l.206-.138a1.5 1.5 0 0 1 2.311 1.001l.14.837a1.5 1.5 0 0 1-.648 1.495l-.658.438A4.522 4.522 0 0 0 7.286 9.42l.44-.658a1.5 1.5 0 0 1 1.494-.648l.837.14a1.5 1.5 0 0 1 1.001 2.311l-.138.207a.5.5 0 0 1-.42.229h-1A5.5 5.5 0 0 1 4 5.5v-1a.5.5 0 0 1 .23-.42Z" clipRule="evenodd"/></svg>                         
						  </a> */}
						</div>
						<div className="cs-height_30 cs-height_lg_30"></div>
					  </div>
					</div>
				  </div>
				  <div className="cs-slide">
					<div className="cs-team cs-style1 text-center cs-white_bg">
					  <div className="cs-height_30 cs-height_lg_30"></div>
					  <div className="cs-member_thumb">
						<img src="/assets/img/p3.png" alt="Member"/>
					  </div>
					  <div className="cs-height_25 cs-height_lg_25"></div>
					  <div className="cs-member_info">
						<h2 className="cs-font_22 cs-font_16_sm cs-m0">Shower Shoes</h2>
						<div className="cs-height_5 cs-height_lg_5"></div>
						<p className="cs-m0">Inside Man</p>
						<div className="cs-height_20 cs-height_lg_20"></div>
						<div className="cs-member_social cs-center">
						 
						  <a  href={props.discord} rel="noreferrer" target="_blank" className="cs-primary_color cs-accent_color_hover">
						  <svg xmlns="https://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
						<g fill="currentColor" stroke="currentColor" strokeWidth="2">
						<path className="st0" d="M20.3,4.6c-1.6-0.7-3.2-1.3-5-1.6c-0.2,0.4-0.5,0.9-0.6,1.3C12.9,4,11.1,4,9.3,4.3C9.1,3.9,8.8,3.4,8.6,3
		c-1.7,0.3-3.4,0.8-5,1.6c-3.1,4.7-4,9.4-3.6,13.9C2.2,20,4.2,21,6.2,21.6c0.5-0.7,0.9-1.4,1.3-2.1c-0.7-0.3-1.4-0.6-2-1
		c0.2-0.1,0.3-0.3,0.5-0.4c3.9,1.8,8.2,1.8,12.1,0c0.2,0.1,0.3,0.3,0.5,0.4c-0.6,0.4-1.3,0.7-2.1,1c0.4,0.8,0.8,1.5,1.3,2.1
		c2-0.6,4-1.6,6.1-3.1C24.4,13.2,23,8.6,20.3,4.6z M8,15.7c-1.2,0-2.2-1.1-2.2-2.5s1-2.5,2.2-2.5c1.2,0,2.2,1.1,2.2,2.5
		C10.2,14.6,9.2,15.7,8,15.7z M16,15.7c-1.2,0-2.2-1.1-2.2-2.5s1-2.5,2.2-2.5c1.2,0,2.2,1.1,2.2,2.5C18.1,14.6,17.2,15.7,16,15.7z"
		/>
						</g>
					</svg>   
						  </a>
						  {/* <a href="#" className="cs-primary_color cs-accent_color_hover">
							<svg xmlns="https://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 15 15"><path fill="currentColor" d="M5 4.768a.5.5 0 0 1 .761.34l.14.836a.5.5 0 0 1-.216.499l-.501.334A4.501 4.501 0 0 1 5 5.5v-.732ZM9.5 10a4.5 4.5 0 0 1-1.277-.184l.334-.5a.5.5 0 0 1 .499-.217l.836.14a.5.5 0 0 1 .34.761H9.5Z"/><path fill="currentColor" fillRule="evenodd" d="M0 7.5a7.5 7.5 0 1 1 3.253 6.182l-2.53 1.265a.5.5 0 0 1-.67-.67l1.265-2.53A7.467 7.467 0 0 1 0 7.5Zm4.23-3.42l.206-.138a1.5 1.5 0 0 1 2.311 1.001l.14.837a1.5 1.5 0 0 1-.648 1.495l-.658.438A4.522 4.522 0 0 0 7.286 9.42l.44-.658a1.5 1.5 0 0 1 1.494-.648l.837.14a1.5 1.5 0 0 1 1.001 2.311l-.138.207a.5.5 0 0 1-.42.229h-1A5.5 5.5 0 0 1 4 5.5v-1a.5.5 0 0 1 .23-.42Z" clipRule="evenodd"/></svg>                         
						  </a> */}
						</div>
						<div className="cs-height_30 cs-height_lg_30"></div>
					  </div>
					</div>
				  </div>
				</div>
			  </div>
			  <div className="cs-pagination cs-style1 cs-hide_desktop cs-accent_color"></div>
			  <div className="cs-slider_arrows cs-style1 cs-center cs-hide_mobile">
				<div className="cs-left_arrow cs-center cs-accent_bg cs-white_color cs-primary_bg_hover">
				  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="https://www.w3.org/2000/svg">
					<path d="M16 4.32031L8 12.3203L16 20.3203" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
				  </svg>              
				</div>
				<div className="cs-right_arrow cs-center cs-accent_bg cs-white_color cs-primary_bg_hover">
				  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="https://www.w3.org/2000/svg">
					<path d="M8 4.32031L16 12.3203L8 20.3203" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
				  </svg>              
				</div>
			  </div>
			</div>
		  </div>
		</section>
	);
};

export default Team;
