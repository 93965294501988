import React from "react";


const MintInfo = (props) => {
	return (
		<section>
    <div className="container">
      <div className="cs-seciton_heading cs-style1 text-uppercase text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
        <h3 className="cs-section_title cs-font_16 cs-font_14_sm cs-gradient_color">The Easy way to a criminal life</h3>
        <h2 className="cs-section_subtitle cs-m0 cs-font_36 cs-font_24_sm">Harbouring Fugitives</h2>
      </div>
      <div className="cs-height_50 cs-height_lg_30"></div>
      <div className="row wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
        <div className="col-xl-3 col-md-6">
          <div className="cs-card cs-style3 text-center cs-accent_bg">
            <div className="cs-card_in">
              <div className="cs-card_number cs-font_30 cs-font_24_sm cs-primary_font cs-body_line_height cs-center">1</div>
              <h3 className="cs-card_title cs-font_30 cs-font_24_sm cs-m0 cs-white_color">Connect to dealer</h3>
			  <small>Connect to your wallet</small>
            </div>
          </div>
          <div className="cs-height_25 cs-height_lg_25"></div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className="cs-card cs-style3 text-center cs-accent_bg">
            <div className="cs-card_in">
              <div className="cs-card_number cs-font_30 cs-font_24_sm cs-primary_font cs-body_line_height cs-center">2</div>
              <h3 className="cs-card_title cs-font_30 cs-font_24_sm cs-m0 cs-white_color">What you holding?</h3>
				<small>Select your count</small>
			</div>
          </div>
          <div className="cs-height_25 cs-height_lg_25"></div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className="cs-card cs-style3 text-center cs-accent_bg">
            <div className="cs-card_in">
              <div className="cs-card_number cs-font_30 cs-font_24_sm cs-primary_font cs-body_line_height cs-center">3</div>
              <h3 className="cs-card_title cs-font_30 cs-font_24_sm cs-m0 cs-white_color">Shake<br/> on it</h3>
				<small>Confirm Transaction</small>
			</div>
          </div>
          <div className="cs-height_25 cs-height_lg_25"></div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className="cs-card cs-style3 text-center cs-accent_bg">
            <div className="cs-card_in">
              <div className="cs-card_number cs-font_30 cs-font_24_sm cs-primary_font cs-body_line_height cs-center">4</div>
              <h3 className="cs-card_title cs-font_30 cs-font_24_sm cs-m0 cs-white_color">A smooth<br/> getaway</h3>
				<small> NFT is now your wallet</small>
			</div>
          </div>
          <div className="cs-height_25 cs-height_lg_25"></div>
        </div>
      </div>
    </div>
  </section>
	);
};

export default MintInfo;
