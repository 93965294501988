import React from "react";


const CTASpacer = (props) => {
	return (
		<div className="cs-height_75 cs-height_lg_45"></div>
	);
};

export default CTASpacer;
