import React from "react";


const Hero = (props) => {



	return(

		<section className="cs-hero cs-style1 cs-type2 cs-ripple_version" data-src="public/assets/img/alcatraz.png" id="home">
		<div className="cs-dark_overlay"></div>
		<div className="container">
		  <div className="cs-hero_text wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.35s">
			<h1 className="cs-hero_title text-uppercase cs-font_60 cs-font_36_sm cs-bold">"Alcatraz <br/>Swim Team"</h1>
			<h1 className="cs-hero_title text-uppercase cs-font_60 cs-font_36_sm cs-bold">MINTING NOW!</h1>
			<h2 className="cs-hero_secondary_title cs-font_22 cs-font_18_sm text-uppercase">yer boy, Viva La Vandal</h2>
			<div className="cs-btn_group">
			  <a href="#" className="cs-btn cs-btn_filed cs-white_btn cs-modal_btn" onClick={props.openMintDialog}><span>Mint Now</span></a>
			  <a href="https://opensea.io/collection/alcatraz-swim-team" target="_blank" rel="noreferer" className="cs-btn cs-btn_filed cs-white_btn"><span>Check us out on opensea now.</span></a><br/><br/>
			</div>

			<h3 className="cs-hero_subtitle cs-font_20 cs-font_16_sm cs-body_line_height">
				PRICE 0.02 ETH	 <br/>

				10 MINTS PER TX	<br/>
				MINT IS LIVE !!!!
			</h3>
		  </div>
		  <div className="cs-hero_img wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
		  <img src="/assets/img/logo-crop-sticker.png" alt="Alcatraz Swim Team Logo"/>
			{/* <div className="cs-hero_img_sm">
			  <img src="assets/img/hero_img_sm.png" alt="Minting soon"/>
			</div> */}
		  </div>
		</div>
	  </section>

	);
};

export default Hero;
