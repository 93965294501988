import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Counters from "./components/Counters";

import MintSite from "./Alcatraz";

export default function App() {
  return (

    <Router>
		<div>
        {/* <Link to="/">Home</Link>
        <Link to="/mint">Mint</Link> */}
        <hr />
        <Routes>
			    <Route path="/" element={<MintSite />} />
        </Routes>
      </div>
    </Router>
  );
}
